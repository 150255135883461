import PropTypes from 'prop-types'
import React from 'react'
import TrackedLink from '@/components/shared/tracked-link'
import Type from '@/components/shared/Type'

const Card = ({
  children,
  eventAction,
  eventCategory,
  eventLabel,
  excerpt,
  heading,
  image,
  to,
  ...rest
}) => {
  const typeProps = Object.assign(
    {
      className: 'sc-rp-card__heading',
      element: 'h3',
    },
    heading,
  )

  delete typeProps.text

  return (
    <div className="sc-rp-card" {...rest}>
      <div className="sc-rp-card__summary">
        <Type {...typeProps}>
          <TrackedLink
            className="sc-rp-card__link"
            eventAction={eventAction}
            eventCategory={eventCategory}
            eventLabel={eventLabel}
            to={to}
          >
            {heading.text}
          </TrackedLink>
        </Type>

        {excerpt && <p className="sc-rp-card__excerpt">{excerpt}</p>}

        {children}
      </div>

      {/* eslint-disable-next-line jsx-a11y/alt-text */}
      <img {...image} className="sc-rp-card__image" />
    </div>
  )
}

Card.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  excerpt: PropTypes.string,
  heading: PropTypes.shape({
    className: PropTypes.string,
    element: PropTypes.string,
    text: PropTypes.string.isRequired,
  }).isRequired,
  image: PropTypes.shape({
    alt: PropTypes.string.isRequired,
    height: PropTypes.number.isRequired,
    src: PropTypes.string.isRequired,
    width: PropTypes.number.isRequired,
  }),
  eventAction: PropTypes.string,
  eventCategory: PropTypes.string,
  eventLabel: PropTypes.string,
  to: PropTypes.string.isRequired,
}

export default Card
