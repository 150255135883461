import ImageBlock from '@/components/shared/image-block'
import React from 'react'
import Section from '@/components/shared/section'
import TrackedLink from '@/components/shared/tracked-link'
import imageLg from './images/christina-lg.jpg'
import imageMd from './images/christina-md.jpg'
import imageSm from './images/christina-sm.jpg'

const heading = {
  text: 'Digital Making at Home',
}

const image = {
  alt: 'Christina Foust, from Raspberry Pi Foundation North America',
  height: 800,
  sources: [
    {
      media: '(min-width: 1000px)',
      srcSet: imageLg,
    },
    {
      media: '(min-width: 600px)',
      srcSet: imageMd,
    },
    {
      srcSet: imageSm,
    },
  ],
  width: 1600,
}

const modifiers = {
  imageBottom: true,
}

const AtHome = () => (
  <Section>
    <ImageBlock
      backgroundColor="rgb(240, 248, 255)"
      heading={heading}
      image={image}
      modifiers={modifiers}
    >
      <p>
        Check out our Digital Making at Home videos: code-along instructions for
        fun, creative projects, plus inspiring conversations with young digital
        makers!
      </p>

      <TrackedLink
        className="sc-rp-button"
        eventCategory="Signpost: Learn"
        eventLabel="DMAH: /at-home button"
        to="/at-home"
      >
        Watch and code along!
      </TrackedLink>
    </ImageBlock>
  </Section>
)

export default AtHome
