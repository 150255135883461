import './hero.scss'

import React from 'react'
import TrackedLink from '@/components/shared/tracked-link'

const Hero = () => (
  <header className="c-lp-hero">
    <h1 className="c-lp-hero__title">Learn at home</h1>

    <div className="c-lp-hero__content">
      <p className="sc-lp-hero__lead">
        As well as learning in school, young people can have fun getting
        creative with tech at home to make things that matter to them.
      </p>

      <p className="c-lp-hero__copy">
        That’s why part of our{' '}
        <a className="sc-rp-link" href="https://www.raspberrypi.org/about/">
          educational mission
        </a>{' '}
        is supporting young people, parents, and educators with free home
        learning resources.
      </p>

      <TrackedLink
        className="sc-rp-button c-lp-hero__button"
        eventCategory="Signpost: Learn"
        eventLabel="Hero: parents' guide button"
        to="/blog/digital-making-at-home-parents-guide"
      >
        Read our guide for parents
      </TrackedLink>
    </div>
  </header>
)

export default Hero
