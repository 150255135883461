import ImageBlock from '@/components/shared/image-block'
import PropTypes from 'prop-types'
import React from 'react'
import Section from '@/components/shared/section'
import TeachImage from './images/teach.png'
import TrackedLink from '@/components/shared/tracked-link'

const heading = {
  text: 'Are you a teacher?',
}

const image = {
  alt: 'An illustration of a teacher and two children looking at a chart',
  height: 716,
  sources: [
    {
      srcSet: TeachImage,
    },
  ],
  width: 1724,
}

const modifiers = {
  imageBottom: true,
}

const TeachBanner = ({ children, eventCategory }) => (
  <Section>
    <ImageBlock
      backgroundColor="#ffffff"
      heading={heading}
      image={image}
      modifiers={modifiers}
    >
      {children}

      <TrackedLink
        className="sc-rp-button"
        eventCategory={eventCategory}
        eventLabel="Are you a teacher?: teach computing button"
        to="https://www.raspberrypi.org/teach/"
      >
        Access resources
      </TrackedLink>
    </ImageBlock>
  </Section>
)

TeachBanner.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.object,
  ]),
  eventCategory: PropTypes.string.isRequired,
}

export default TeachBanner
