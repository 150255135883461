import './MissionCta.scss'

import PropTypes from 'prop-types'
import React from 'react'
import Section from '@/components/shared/section'
import TrackedLink from '@/components/shared/tracked-link'
import playIcon from './images/icon-play.svg'

const MissionCta = ({ eventCategory }) => (
  <Section>
    <div className="c-mission">
      <div className="c-mission__container">
        <div className="c-mission__content">
          <h2 className="sc-rp-type-secondary-heading">
            Our mission has never been more&nbsp;vital
          </h2>

          <p>
            Every young person, whatever their background and wherever they are
            in the world, deserves the opportunity to learn digital skills and
            gain the confidence to create with digital technologies.
          </p>

          <TrackedLink
            className="sc-rp-link"
            eventCategory={eventCategory}
            eventLabel="MissionCta: Support our mission link"
            to="/support-us/"
          >
            Support our mission
          </TrackedLink>
        </div>

        <TrackedLink
          className="c-mission__video-link"
          eventCategory="Signpost: Learn"
          eventLabel="MissionCta: TEDx talk YouTube link"
          to="https://www.youtube.com/watch?v=yxSe2svMk1Q"
          rel="noopener noreferrer"
          target="_blank"
        >
          <img
            alt="Watch CEO of the Raspberry Pi Foundation, Philip Colligan, talk about our mission at TEDx"
            className="c-mission__play-icon"
            src={playIcon}
          />
        </TrackedLink>
      </div>
    </div>
  </Section>
)

MissionCta.propTypes = {
  eventCategory: PropTypes.string.isRequired,
}

export default MissionCta
