import './learn.scss'

import ApplicationLayout from '@/Layouts/Application'
import AstroPiCompetition from '@/components/shared/AstroPiCompetition'
import AtHome from '@/components/learn/at-home'
import CoolestProjects from '@/components/learn/coolest-projects'
import Hero from '@/components/learn/hero'
import IntroducingCodeClubWorld from '@/components/learn/introducing-code-club-world'
import MissionCta from '@/components/shared/MissionCta'
import PageMeta from '@/components/Application/PageMeta'
import Projects from '@/components/learn/projects'
import React from 'react'
import TeachBanner from '@/components/shared/TeachBanner'
import opengraphImageSrc from './images/opengraph.png'
import pageMeta from './config/meta.json'

const LearnLandingPageTemplate = () => {
  const { description, title } = pageMeta

  return (
    <ApplicationLayout>
      <PageMeta
        description={description}
        image={opengraphImageSrc}
        title={title}
      />
      <Hero />
      <IntroducingCodeClubWorld />
      <Projects />
      <CoolestProjects />
      <TeachBanner eventCategory="Signpost: Learn">
        <p>
          Discover our free teaching resources and online training courses to
          help you teach computing with confidence. We also have coding
          competitions and support for starting your own coding club to keep
          your learners engaged in digital making.
        </p>
      </TeachBanner>
      <AstroPiCompetition eventCategory="Signpost: Learn">
        <p>
          Every year, young people take part to write code that runs on
          Raspberry Pi computers aboard the International Space Station.
        </p>
      </AstroPiCompetition>
      <AtHome />
      <MissionCta eventCategory="Signpost: Learn" />
    </ApplicationLayout>
  )
}

export default LearnLandingPageTemplate
