import ImageBlock from '@/components/shared/image-block'
import React from 'react'
import Section from '@/components/shared/section'
import TrackedLink from '@/components/shared/tracked-link'
import imageLg from './images/coolest-projects-lg.jpg'
import imageMd from './images/coolest-projects-md.jpg'
import imageSm from './images/coolest-projects-sm.jpg'

const heading = {
  text: 'Coolest Projects Global',
}

const image = {
  alt: 'A Maker shows their creation at Coolest Project International 2019',
  height: 800,
  sources: [
    {
      media: '(min-width: 1000px)',
      srcSet: imageLg,
    },
    {
      media: '(min-width: 600px)',
      srcSet: imageMd,
    },
    {
      srcSet: imageSm,
    },
  ],
  width: 1600,
}

const CoolestProjects = () => (
  <Section>
    <ImageBlock
      backgroundColor="rgb(255, 243, 236)"
      heading={heading}
      image={image}
    >
      <p>
        Our world-leading annual technology showcase lets young people around
        the world share their tech creations, celebrating their achievements.
        Young people can now register to show off their projects in the online
        showcase gallery.
      </p>

      <TrackedLink
        className="sc-rp-button"
        eventCategory="Signpost: Learn"
        eventLabel="Coolest Projects: coolestprojects.org link"
        to="https://coolestprojects.org/"
      >
        Get involved
      </TrackedLink>
    </ImageBlock>
  </Section>
)

export default CoolestProjects
