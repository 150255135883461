import './card-list.scss'

import Card from '@/components/shared/card'
import PropTypes from 'prop-types'
import React from 'react'

const CardList = ({ children, ...rest }) => {
  return (
    <ul className="c-card-list" {...rest}>
      {React.Children.map(children, (child) => (
        <li className="c-card-list__item" key={child.props.id}>
          <child.type {...child.props} />
        </li>
      ))}
    </ul>
  )
}

CardList.propTypes = {
  children: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.oneOf([Card]),
    }),
  ),
}

export default CardList
