import imagePython from '../images/python.png'
import imageScratch from '../images/scratch.png'
import imageUnity from '../images/unity.png'

const excerpts = {
  python: 'Make art, games, and graphs by typing your code on a keyboard',
  scratch: 'Make games, animations, and stories with click-and-drag coding',
  unity: 'Design 3D worlds in C# on the Unity development platform',
}

const headings = {
  python: 'Code in Python',
  scratch: 'Code in Scratch',
  unity: 'Code with Unity',
}

const images = {
  python: imagePython,
  scratch: imageScratch,
  unity: imageUnity,
}

const imageObject = {
  alt: '',
  height: 360,
  width: 680,
}

const projectMeta = (id, link) => {
  const to =
    link || `https://projects.raspberrypi.org/en/codeclub/${id}-module-1`
  const eventLabel = `Projects: ${id} card`
  const eventCategory = 'Signpost: Learn'

  return {
    eventCategory,
    eventLabel,
    excerpt: excerpts[id],
    heading: {
      text: headings[id],
    },
    id,
    image: Object.assign({}, imageObject, { src: images[id] }),
    to,
  }
}

export default [
  projectMeta(
    'scratch',
    'https://projects.raspberrypi.org/en/pathways/scratch-intro'
  ),
  projectMeta(
    'python',
    'https://projects.raspberrypi.org/en/pathways/python-intro'
  ),
  projectMeta(
    'unity',
    'https://projects.raspberrypi.org/en/pathways/unity-intro'
  ),
]
