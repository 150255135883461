import ImageBlock from '@/components/shared/image-block'
import React from 'react'
import Section from '@/components/shared/section'
import TrackedLink from '@/components/shared/tracked-link'
import imageLg from './images/ccw-lg.png'
import imageMd from './images/ccw-md.png'
import imageSm from './images/ccw-sm.png'

const heading = {
  text: 'Introducing Code Club World',
}

const image = {
  alt: '',
  height: 800,
  sources: [
    {
      media: '(min-width: 1000px)',
      srcSet: imageLg,
    },
    {
      media: '(min-width: 760px)',
      srcSet: imageMd,
    },
    {
      srcSet: imageSm,
    },
  ],
  width: 1600,
}

const IntroducingCodeClubWorld = () => (
  <Section>
    <ImageBlock
      backgroundColor="rgb(240, 248, 255)"
      heading={heading}
      image={image}
    >
      <p>
        Helping young people, aged 9 to 13, to learn how to code through fun,
        creative making activities and projects.
      </p>

      <TrackedLink
        className="sc-rp-button"
        eventCategory="Signpost: Learn"
        eventLabel="Introducing Code Club World: CTA button'"
        to="https://codeclubworld.org/"
      >
        Visit Code Club World
      </TrackedLink>
    </ImageBlock>
  </Section>
)

export default IntroducingCodeClubWorld
