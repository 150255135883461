import './projects.scss'

import Card from '@/components/shared/card'
import CardList from '@/components/shared/card-list'
import Container from '@/components/shared/container'
import React from 'react'
import Section from '@/components/shared/section'
import TrackedLink from '@/components/shared/tracked-link'
import projectsData from './config/projects-data.js'

const Projects = () => (
  <Section>
    <Container>
      <div className="c-learn-projects">
        <h2 className="sc-rp-type-secondary-heading">
          Learn to code with our projects
        </h2>

        <p>
          The projects include step-by-step instructions to support coding for
          kids, teenagers and young adults of all ages and skill levels. Plus
          they’re available in up to 30 languages and take less than an hour to
          complete.
        </p>

        <CardList>
          {projectsData.map((cardData, index) => (
            <Card key={`card-index-${index}`} {...cardData} />
          ))}
        </CardList>

        <div className="c-learn-projects__button">
          <TrackedLink
            className="sc-rp-button"
            eventCategory="Signpost: Learn"
            eventLabel="Projects: view all projects"
            to="https://projects.raspberrypi.org/"
          >
            View all projects
          </TrackedLink>
        </div>
      </div>
    </Container>
  </Section>
)

export default Projects
